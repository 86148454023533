$(function () {
    $.datepicker.setDefaults($.datepicker.regional["nl"]);

    $.validator.methods.date = function (value, element) {
        return this.optional(element) || moment(value, "D-M-YYYY", true).isValid();
    }

    $.validator.methods.range = function (value, element, param) {
        var globalizedValue = value.replace(",", ".");
        return this.optional(element) || (globalizedValue >= param[0] && globalizedValue <= param[1]);
    }

    $.validator.methods.number = function (value, element) {
        return this.optional(element) || /^(?:-?\d+|-?\d{1,3}(?:.\d{3})+)?(?:\,\d+)?$/.test(value);
    }
});